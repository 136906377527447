import { Button } from "@mui/material";
import * as React from "react";
import { buttonStyle } from "../../buttonFunction";
import { checkStringLength } from "../../dataLengthCheck";

export const columnHeaders = (navigateToDetails: any, setGuideReviewStatus: any, isFromGuide: boolean) => {
  return [
    { headerName: "No.", field: "id", width: 50 },
    {
      headerName: "Guide Status", field: "becomeGuideStatus", sortable: true, width: 180, renderCell: (params: any) => {
        const showButton = () => {
          if (params?.row?.becomeGuideStatus === 'PendingApproval') {
            return (
              <Button
                sx={[buttonStyle, {
                  color: "white",
                  backgroundColor: "#FE6331",
                  "&:hover": {
                    borderColor: "#FE6331",
                    backgroundColor: "rgba(254, 99, 49, 0.8)"
                  },
                }]}
                variant="outlined"
                onClick={() => {
                  const user = params.row
                  setGuideReviewStatus({
                    id: user.id,
                    userId: user.userId,
                    userName: `${user.firstName} ${user.lastName}`,
                    isClicked: true,
                    status: ''
                  })
                }}
              >
                {"Pending"}
              </Button>)
          } else if (params?.row?.becomeGuideStatus === 'Approved') {
            return (
              'Approved'
            )
          } else if (params?.row?.becomeGuideStatus === 'Rejected') {
            return (
              'Rejected'
            )
          } else {
            return ''
          }
        }
        return (
          showButton()
        )
      }
    },
    {
      field: "actions",
      headerName: "More Info",
      sortable: false,
      disableClickEventBubbling: true,
      minWidth: 160,
      renderCell: (params: any) => {
        return (
          <Button
            sx={buttonStyle}
            variant="outlined"
            onClick={() => navigateToDetails(params.row.userId)}
          >
            {"Details"}
          </Button>
        );
      },
    },
    { headerName: "User ID", field: "userId", minWidth: 130 },
    {
      headerName: "First Name",
      field: "firstName",
      minWidth: 200,
      renderCell: (params: any) => (
        <p title={params.row.firstName}>
          {checkStringLength(params.row.firstName ?? "NA", 20)}
        </p>
      ),
    },
    {
      headerName: "Last Name",
      field: "lastName",
      minWidth: 200,
      renderCell: (params: any) => (
        <p title={params.row.lastName}>
          {checkStringLength(params.row.lastName ?? "NA", 20)}
        </p>
      ),
    },
    {
      headerName: "Country",
      field: "country",
      minWidth: 150,
      renderCell: (params: any) => {
        return (
          <p>
            {params.row.country?.length > 0 &&
              params.row.country.map((eachData: any) => eachData.countryName)}
          </p>
        );
      },
    },
    {
      headerName: "Email",
      field: "email",
      flex: 1,
      minWidth: 350,
      renderCell: (params: any) => (
        <p title={params.row.email}>{checkStringLength(params.row.email ?? "NA")}</p>
      ),
    },
    {
      headerName: "Phone",
      field: "phone",
      minWidth: 220,
      renderCell: (params: any) => (
        <p title={params.row.phone}>{params.row.phone ?? "NA"}</p>
      ),
    },
    {
      headerName: "User Name",
      field: "userHandle",
      renderCell: (params: any) => (
        <p title={params.row.userHandle}>
          {checkStringLength(params.row.userHandle ?? "NA", 25)}
        </p>
      ),
      minWidth: 270,
    },
    isFromGuide ? {
      headerName: "Guide Completed Class",
      field: "guidecompletedClassCount",
      flex: 1,
      minWidth: 250,
      renderCell: (params: any) => (
        <p title={params.row.guidecompletedClassCount}>
          {checkStringLength(params.row.guidecompletedClassCount ?? 0)}
        </p>
      ),
    } : {
      headerName: "Explorer Attended Class",
      field: "explorerattendedClassCount",
      flex: 1,
      minWidth: 250,
      renderCell: (params: any) => (
        <p title={params.row.explorerattendedClassCount}>
          {checkStringLength(params.row.explorerattendedClassCount ?? 0)}
        </p>
      ),
    },

    isFromGuide ? {
      headerName: "Guide Canceled Class",
      field: "guidecanceledClassCount",
      flex: 1,
      minWidth: 250,
      renderCell: (params: any) => (
        <p title={params.row.guidecanceledClassCount}>
          {checkStringLength(params.row.guidecanceledClassCount ?? 0)}
        </p>
      ),
    } : {
      headerName: "Explorer Canceled Class",
      field: "explorercanceledClassCount",
      flex: 1,
      minWidth: 250,
      renderCell: (params: any) => (
        <p title={params.row.explorercanceledClassCount}>
          {checkStringLength(params.row.explorercanceledClassCount ?? 0)}
        </p>
      ),
    },
    isFromGuide && {
      headerName: "Guide Class List Count",
      field: "guideclassListCount",
      flex: 1,
      minWidth: 250,
      renderCell: (params: any) => (
        <p title={params.row.guideclassListCount}>
          {checkStringLength(params.row.guideclassListCount ?? 0)}
        </p>
      ),
    },
    !isFromGuide && {
      headerName: "Explorer Bookmarked Class",
      field: "bookMarkedClassCount",
      flex: 1,
      minWidth: 250,
      renderCell: (params: any) => (
        <p title={params.row.bookMarkedClassesCount}>
          {checkStringLength(params.row.bookMarkedClassesCount ?? 0)}
        </p>
      ),
    }

  ];
};
