/** class tab api calls */
import { PATH_PARAMS } from "../constants";
import { SuccessResponse } from "../types/parameters.type";
import { ApiClient, ErrorHandler, SuccessHandler } from "../services/data.service";

export const fetchExplorersController = (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  params?: any
) => {
  ApiClient.makeGetRequest(
    PATH_PARAMS.GET_EXPLORERS_LIST,
    successHandler,
    errorHandler,
    params
  );
};

export const fetchSingleExplorerDetailsController = (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  params?: any
) => {
  ApiClient.makeGetRequest(
    `${PATH_PARAMS.GET_SINGLE_EXPLORER_DETAILS}${params}`,
    successHandler,
    errorHandler
  );
};

export const fetchExplorerClassController = (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  explorerId?: string | number,
  params?: any
) => {
  ApiClient.makeGetRequest(
    `${PATH_PARAMS.GET_EXPLORER_CLASS}${explorerId}`,
    successHandler,
    errorHandler,
    params
  );
};

export const updateExplorerController = (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  explorerId?: string | number,
  params?: any
) => {
  ApiClient.makePutRequest(
    `${PATH_PARAMS.UPDATE_EXPLORER}${explorerId}`,
    successHandler,
    errorHandler,
    params
  );
};

export const fetchExplorerBookmarkedClassController = (
  successHandler: SuccessHandler<SuccessResponse>,
  errorHandler: ErrorHandler,
  explorerId?: string | number,
  params?: any
) => {
  ApiClient.makeGetRequest(
    `${PATH_PARAMS.GET_EXPLORER_BOOKMARKED_CLASS}${explorerId}`,
    successHandler,
    errorHandler,
    params
  );
};