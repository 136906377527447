import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { getExplorerData } from "../../../utils/reusable-functions/guidesOrExplorers/view-guideOrExplorer/viewExplorerApiData";
import { guidesOrExplorerClassColumnHeader } from "../../../utils/reusable-functions/guidesOrExplorers/view-guideOrExplorer/tableHeader";
import { DetailsViewPageComponent } from "../../../compoundComponents";
import { getGuideOrExplorerClass } from "../../../utils/reusable-functions/guidesOrExplorers/view-guideOrExplorer/guideExplorerClassTableData";

export default function ViewExplorerDetailsPage() {
  const navigate = useNavigate();
  const location: any = useLocation();

  const [pageState, setPageState] = React.useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 0,
    pageSize: 10,
  });

  const [viewData, setViewData] = React.useState<any>({
    data: null,
    isLoading: false,
    explorerImage: null,
    explorerName: null,
  });
  const [bookmarkedPageState, setBookmarkedPageState] = React.useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 0,
    pageSize: 10,
  });
  React.useEffect(() => {
    if (location?.state?.explorer_id) {
      getExplorerData(location.state.explorer_id, setViewData);
    }
  }, []);

  React.useEffect(() => {
    if (location?.state?.explorer_id) {
      getGuideOrExplorerClass(
        location.state.explorer_id,
        setPageState,
        setBookmarkedPageState,
        pageState,
        bookmarkedPageState,
        "explorer",
      );
    }
  }, [pageState.page, pageState.pageSize,bookmarkedPageState.page, bookmarkedPageState.pageSize]);

  return (
    <DetailsViewPageComponent
      pageState={pageState}
      setPageState={setPageState}
      bookmarkedPageState={bookmarkedPageState}
      setBookmarkedPageState={setBookmarkedPageState}
      viewData={{
        ...viewData,
        profileImage: viewData?.explorerImage,
        name: viewData?.explorerName,
      }}
      button1={{
        handleClick: () =>
          navigate("/admin/edit-explorer", {
            state: { explorerId: location?.state?.explorer_id },
          }),
      }}
      showViews={{ button1: true, button2: true }}
      tableColumns={guidesOrExplorerClassColumnHeader()}
    />
  );
}
